import React, { useState, useEffect } from "react";
import { Menu, Button } from "antd";
import styled from "styled-components";
import { MenuOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import store from "../store/store";

const MenuList = styled.div`
	display: flex;
	justify-content: space-between;
`;

const NavTop = styled.div`
	display: flex;
	justify-content: flex-end;
	button {
		color: black;
		border: none;
		box-shadow: none;
	}
`;

const NavWrapper = styled.div`
     a {
        text-decoration: none;
    }
`

const Navbar = () => {
	const [toggleMenu, setToggleMenu] = useState(false);
	const [toggleBar, setToggleBar] = useState(true);
	const navigate = useNavigate();
    
	const toggleChange = () => {
		setToggleMenu(!toggleMenu);
		setToggleBar(!toggleBar);
	};

	const onMenuClick = () => {
		setToggleMenu(!toggleMenu);
		setToggleBar(!toggleBar);
	};

	const logout = () => {
		store.dispatch({
			type: "LOG_OUT",
		});
		navigate("/");

		// axios.post('/api/logout.php')
		//     .then((res) => {
		//         console.log(res.data);
		//         store.dispatch({
		//             type: 'LOG_OUT',
		//             users: res.data.userinfo
		//         })
		//         navigate('/')

		//     })
	};

	return (
		<NavWrapper>
			<NavTop>
				<Button type="" onClick={toggleChange}>
					{toggleBar ? <MenuOutlined /> : <MenuFoldOutlined />}
				</Button>
			</NavTop>
			{toggleMenu && (
				<Menu defaultSelectedKeys={["1"]} mode="inline" theme="light" inlineCollapsed={toggleBar} onClick={onMenuClick}>
					<Menu.Item key="subs">
						<Link to="/main">입출금</Link>
					</Menu.Item>
					<Menu.Item key="product">
						<Link to="/lockup">락업목록</Link>
					</Menu.Item>
					<hr></hr>
					<Menu.Item key="cs">
						<Link to="/mypage">마이페이지</Link>
					</Menu.Item>
					<Menu.Item key="signin">
						<Link to="/mypage/">보안인증</Link>
					</Menu.Item>
					<Menu.Item key="signup">
						<Link to="/mypage/loginhistory">로그인내역</Link>
					</Menu.Item>
					<Menu.Item key="signin">
						<Link to="/mypage/password">비밀번호 변경</Link>
					</Menu.Item>
					<Menu.Item key="signup">
						<Link to="/mypage/delete">회원 탈퇴</Link>
					</Menu.Item>
					<hr />
					<Menu.Item key="signup">
						<a onClick={logout}>로그아웃</a>
					</Menu.Item>
				</Menu>
			)}
		</NavWrapper>
	);
};

export default Navbar;
