import logo from "../img/logo.png";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { Mobile, PC } from "./ResponsiveController";
import Navbar from "./Dropdown/Navbar";
import store from "./store/store";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useEffect } from "react";

const HeadWrapper = styled.header`
	height: 70px;
	padding: 10px 10px;
	display: flex;
	justify-content: space-between;
	backdrop-filter: blur(10px);

	img {
		height: 50px;
	}
`;

const LogoWrapper = styled.div`
	display: flex;

	a,
	div {
		line-height: 50px;
		text-align: center;
		text-decoration: none;
		color: black;
	}

	.abtn {
		width: 100px;
	}

	ul {
		width: 100px;
		cursor: pointer;
		text-align: center;
		list-style: none;
		display: inline-block;
		padding-left: 0;

		.DropdownWrapper {
			padding: 4px;
			border-radius: 6px;
			background-color: white;
			box-shadow: 0 2px 12px 0 rgba(37, 42, 49, 0.08), 0 2px 5px 0 rgba(37, 42, 49, 0.15);
			display: inline-block;
		}

		li {
			line-height: 30px;
			cursor: pointer;
			list-style: none;
			width: 100px;
		}
	}

	.log_hover:hover {
		transition: all 0.5s ease;
		color: #00ff00;
	}
`;

const BtnWrapper = styled.div`
	align-items: center;
	line-height: 50px;

	a {
		line-height: 50px;
		text-align: center;
		text-decoration: none;
		color: black;
		font-size: 14px;
		font-family: "Apple SD Gothic Neo" !important;
		font-weight: 900;
	}

	button {
		font-size: 14px;
		font-family: "Apple SD Gothic Neo" !important;
		font-weight: 900;
	}
`;

const Header = ({ children }) => {
	const navigate = useNavigate();
	const isLoggedIn = useSelector((state) => state.userReducer.user.isLoggedIn);

	const logout = () => {
		axios.post("/api/logout.php").then((res) => {
			store.dispatch({
				type: "LOG_OUT",
				users: res.data.userinfo,
			});
			navigate("/");
		});
	};

	return (
		<>
			{isLoggedIn ? (
				<HeadWrapper className="sticky-top top-0 z-40">
					<LogoWrapper>
						<Link to="/">
							<img src={logo} />
						</Link>
					</LogoWrapper>
					<PC>
						<BtnWrapper>
							<Link to="/main" className="ml-2 abtn btn">
								입출금
							</Link>
							<Link to="/lockup" className="ml-2 abtn btn">
								락업 목록
							</Link>
							<button className="btn text-black" onClick={logout}>
								로그아웃
							</button>
							<Link to="/mypage" className="ml-2 abtn btn">
								마이페이지
							</Link>
						</BtnWrapper>
					</PC>
					<Mobile>
						<Navbar></Navbar>
					</Mobile>
				</HeadWrapper>
			) : (
				""
			)}
		</>
	);
};

export default Header;
