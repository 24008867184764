import { useState, useCallback } from "react";
import { Route, Routes } from "react-router-dom";
import { styled } from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Form, Alert, Select, Input, Button } from "antd";
import store from "./store/store";

const LoginForm = styled.div`
	width: 100%;

	.global_bg {
		background-color: #961e23;
	}

	.global_bg:hover {
		transition: all 0.3s ease;
		background-color: #961e23 !important;
		filter: brightness(130%);
	}

	.ant-form-item-required span {
		display: none;
	}

	.ant-form-item-required:before {
		display: none !important;
	}
`;

const LoginFile = () => {
	const navigate = useNavigate();

	const initData = {
		status: "",
		message: "",
		userinfo: {
			userNM: "",
			mobileNO: "",
			profileImg: "",
		},
	};

	const [formData, setFormData] = useState(initData);
	const [error, setError] = useState(null);
	const [errorMsg, setErrorMsg] = useState("로그인 정보를 확인하세요!");

	const onChangeInput = (e) => {
		setFormData({
			...formData,
			[e.target.dataset.name2]: e.target.value,
		});
	};



	const submitForm = (e) => {
		const params = {
			userID: formData.userID,
			password: formData.password,
		};

		// navigate("/main");
		// store.dispatch({
		// 	type: "LOG_IN",
		// 	data: testData.userID,
		// 	users: testData.userinfo,
		// });

		axios
			.post("/api/login.php", params)
			.then((res) => {
				
				if (res.status === 200 && res.data.status === "success") {
					navigate("/main");
					store.dispatch({
						type: "LOG_IN",
						data: res.data.userinfo.id,
						users: res.data.userinfo,
					});
				} else {
					setError(true);
					setErrorMsg(res.data.message)
				}
			})
			.catch((error) => {
				console.log(error.response);
			});
	};

	return (
		<>
			<LoginForm>
				<Form onFinish={submitForm} autoComplete="off" layout="vertical">
					<Form.Item
						name="userID"
						label="아이디"
						requiredMark="optional"
						rules={[
							{
								required: true,
								message: "아이디를 입력해주세요",
							},
						]}
					>
						<Input placeholder="아이디" onChange={onChangeInput} value={formData.userID} data-name2="userID" size="large" />
					</Form.Item>

					<Form.Item
						name="password"
						label="비밀번호"
						rules={[
							{
								required: true,
								message: "비밀번호를 입력해주세요",
							},
						]}
					>
						<Input.Password size="large" placeholder="비밀번호" data-name2="password" onChange={onChangeInput} value={formData.password} />
					</Form.Item>

					{error && <Alert message={errorMsg} type="error"></Alert>}
					<Form.Item>
						<Button type="primary" htmlType="submit" size="large" className="float-end w-100 mt-3 global_bg">
							로그인
						</Button>
					</Form.Item>
				</Form>
			</LoginForm>
		</>
	);
};

export default LoginFile;
