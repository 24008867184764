import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './components/Main';
import Test from './components/Tests';
import Header from './components/Header';
import Footer from './components/Footer';
import Login from './components/Login';
import { Provider, useSelector } from 'react-redux';
import store from './components/store/store';
import { PersistGate } from 'redux-persist/integration/react';
import Lockup from './components/Lockup';
import { persistStore } from 'redux-persist';
import Mypage from './components/Mypage';
import KRWDeposit from './components/KRWDeposit';
import CoinDeposit from './components/CoinDeposit';





const persistor = persistStore(store);

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}></PersistGate>
      <BrowserRouter>
        <Header />
        <Routes>

          <Route path="/main" element={<Main />}>
            <Route exact path="/main/KRW" element={<KRWDeposit />} />
            <Route path="/main/*" element={<CoinDeposit />} />
          </Route>
          
          <Route path="/lockup" element={<Lockup />}/>
          <Route exact path="/mypage" element={<Mypage />}>
            <Route path="/mypage/loginhistory" element={<Mypage />} />
            <Route path="/mypage/password" element={<Mypage />} />
            <Route path="/mypage/delete" element={<Mypage />} />
          </Route>


          <Route exact path="/" element={<Login />}>
            <Route exact path="/register" element={<Login />}></Route>
            <Route exact path="/register" element={<Login />}></Route>
          </Route>

          {/* <Route path="/trade/:name" element={<Test />}></Route>
          <Route exact path="/trade" element={<Test />}></Route> */}




        </Routes>
        <Footer />
      </BrowserRouter>
    </Provider>
  );
}

export default App;

