import { styled } from "styled-components";

import { FaUserCheck, FaAddressCard } from "react-icons/fa6";
import { LuMailCheck } from "react-icons/lu";
import { useSelector } from "react-redux";
import { useState } from "react";
import axios from "axios";
import { devices } from "../Constants/Device";

const Wrapper = styled.div`
	border-top: 1px solid #e2e2e2;
	margin-top: 2%;
	padding-top: 4%;
	.mini_title {
		width: 100%;
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 1.4%;
		display: flex;
		.line-hr {
			background-color: #961e23;
			width: 23px;
			height: 4px;
			margin-top: 12px;
			margin-right: 8px;
		}
	}

	.security_body {
		display: flex;

		@media ${devices.lg} {
			& {
				flex-direction: column;
			}
		}

		.se_card {
			height: 320px;
			border: 1px solid #edeeef;
			flex: 1;
			text-align: center;
			padding: 60px 50px 0;

			svg {
				margin-bottom: 20px;
			}
			h1 {
				margin-bottom: 5px !important;
				font-size: 14px;
				font-weight: 600;
				color: #961e23;
			}

			p {
				font-size: 14px;
				font-weight: 700;
				margin-bottom: 20px !important;
			}

			span {
				font-size: 12px;
				display: block;
				margin-bottom: 20px;
			}
		}
	}

	.se_text_block {
		font-size: 15px;
		font-weight: 600;
		margin: 0 auto;
		width: 100%;
		text-align: center;
		margin-top: 6%;
		margin-bottom: 8%;
	}

	table {
		width: 100%;
		text-align: center;
		th {
			width: 33%;
			border: 1px solid #edeeef;
			background-color: #961e23;
			color: #fff;
			padding: 10px 0;
		}
		td {
			width: 33%;
			border: 1px solid #edeeef;
			padding: 10px 0;
		}
	}

	.last_div {
		border-top: 1px solid #e2e2e2;
		padding-top: 15px;
		margin-top: 20px;
		font-size: 13px;
	}
`;

const Security = () => {
	const user = useSelector((state) => state.userReducer.user);

	const [email, setEmail] = useState(user.userinfo.email_checked);

	const grade = 3;

	const sendEmail = () => {
		axios.post("/api/send_email.php").then((res) => {
			console.log(res.data);
			alert("이메일 전송완료");
		});
	};
	return (
		<>
			<div className="wr-1">
				<div className="title">
					<div className="line"></div>
					보안인증
				</div>
				<div className="subtitle">회원님의 보안 인증 레벨을 확인할 수 있습니다.</div>
				<Wrapper>
					<div className="mini_title">
						<div className="line-hr"></div>
						보안 등급
					</div>
					<div className="security_body">
						<div className="se_card">
							<FaUserCheck size="48" />
							<h1>보안 1 단계</h1>
							<p>회원 가입 완료</p>
							<span>회원 필수 정보(아이디,패스워드,이메일)를 입력해주세요.</span>
						</div>
						<div className="se_card">
							<FaAddressCard size="48" />
							<h1>보안 2 단계</h1>
							<p>이름 입력</p>
							<span>이름을 입력해주세요.</span>
						</div>
						<div className="se_card">
							<LuMailCheck size="48" />
							<h1>보안 3 단계</h1>
							<p>이메일 인증</p>
							<span>이메일 인증을 완료해주세요.</span>
							{grade == 2 && (
								<button className="btn btn-primary" onClick={sendEmail}>
									인증 메일 보내기
								</button>
							)}
						</div>
					</div>

					<div className="se_text_block">
						{user.userID} 님의 보안 등급은 {grade} 단계 입니다.
					</div>

					<div className="mini_title">
						<div className="line-hr"></div>
						등급별 서비스
					</div>

					<table>
						<thead>
							<th>1 단계</th>
							<th>2 단계</th>
							<th>3 단계</th>
						</thead>
						<tbody>
							<td>-</td>
							<td>-</td>
							<td>
								코인 입출금
								<br />
								코인 구매
							</td>
						</tbody>
					</table>

					<div className="last_div">※ 보안 등급별 회원 서비스가 제한되기 때문에 높은 등급일 수록 다양한 서비스를 이용하실 수 있습니다.</div>
				</Wrapper>
			</div>
		</>
	);
};

export default Security;
