import { Alert } from "antd";
import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";

const PasswordWrapper = styled.div`
	border-top: 1px solid #dfe0e5;
	margin-top: 20px;
	.formWrapper {
		width: 70%;
		margin: 0px auto;
		padding: 40px 0;
	}

	input {
		border-radius: 0;
		padding: 10px;
		width: 100%;
		border: 1px solid #dfe0e5;
		font-size: 12px;
		transition: all 1s ease;
		outline: none;
	}

	input:focus {
		border-radius: 0;
		outline: 1px solid #961e23;
		transition: all 1s ease;
	}

	.fg_title {
		font-size: 13px;

		display: flex;
		align-items: center;
	}

	.form_group {
		display: flex;
		flex-direction: column;
	}

	.form_group + .form_group {
		margin-top: 10px;
	}
	.ant-form-item-required span {
		display: none;
	}

	.ant-form-item-required:before {
		display: none !important;
	}

	button {
		float: right;
		display: block;
		padding: 10px 20px;
		text-align: center;
		background: #961e23;
		color: white;
		border-radius: 2px;
		border: none;
	}

	button:hover {
		transition: all 0.3s ease;
		background-color: #961e23 !important;
		filter: brightness(130%);
	}

	.textWrapper {
		font-size: 13px;
		border-top: 1px solid #dfe0e5;
		margin-top: 10px;
		padding: 10px 0;
	}
`;



const Password = () => {
	const user = useSelector((state) => state.userReducer.user);

	const initData = {
		cpw: "",
		npw: "",
		npwc: "",
	};
	
	const [formData, setFormData] = useState(initData);
	const [error, setError] = useState(null);
	const [errorMsg, setErrorMsg] = useState("로그인 정보를 확인하세요!");
	const navigate = useNavigate();

	const onChangeInput = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};
	
	const submitForm = (e) => {
		const params = {
			userID:user.userID,
			cpw: formData.cpw,
			npw: formData.npw,
			npwc: formData.npwc,
		};

		axios
			.post("/api/password.php", params)
			.then((res) => {
				
				if (res.status === 200 && res.data.status === "success") {
					alert("변경이 완료되었습니다")
					navigate("/mypage")
				} else {
					setError(true);
					setErrorMsg(res.data.message)
				}
			})
			.catch((error) => {
				console.log(error.response);
			});
	};
	
	return (
		<>
			<div className="wr-1">
				<div className="title">
					<div className="line"></div>
					비밀번호 변경
				</div>
				<div className="subtitle">주기적인 비밀번호 변경을 통해 개인정보를 안전하게 보호하세요.</div>
				<PasswordWrapper>
					<div className="formWrapper">
						<div className="form_group">
							<div className="fg_title">현재 비밀번호</div>
							<input type="password" name="cpw" onChange={onChangeInput} value={formData.cpw}/>
						</div>
						<div className="form_group">
							<div className="fg_title">새 비밀번호</div>
							<input type="password" name="npw" onChange={onChangeInput} value={formData.npw}/>
						</div>
						<div className="form_group">
							<div className="fg_title">새 비밀번호 확인</div>
							<input type="password" name="npwc" onChange={onChangeInput} value={formData.npwc}/>
						</div>
					</div>
					{error && <Alert message={errorMsg} type="error"></Alert>}
					<div className="textWrapper">※ 비밀번호는 소문자 + 숫자(4자리 ~ 12자리 이내)로 등록하셔야 합니다.</div>
					<button onClick={() => submitForm()}>변경하기</button>
				</PasswordWrapper>
			</div>
		</>
	);
};

export default Password;
