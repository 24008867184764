/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import React, { useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import store from "./store/store";
import axios from "axios";
import { styled } from "styled-components";
import { devices } from "./Constants/Device";
import eth from "../img/eth.svg";
import krw from "../img/krw.svg";
import xrp from "../img/xrp.svg";
import btc from "../img/btc.svg";
import { FaRotate } from "react-icons/fa6";
import CoinDeposit from "./CoinDeposit";
import { NavLink, Route, Routes, useNavigate } from "react-router-dom";
import avs from "../img/avs.png";
import KRWDeposit from "./KRWDeposit";

const MainWrapper = styled.div`
	background-color: #fff;
	width: 100%;
	margin-bottom: 80px;
	margin-top: 75px;
	width: 600px;
	min-height: 750px;

	@media ${devices.lg} {
		& {
			width: 100%;
			height: auto;
			min-height: auto;
			margin-bottom: 10px;
			margin-top: 0px;
		}
	}

	.money_wrapper {
		padding: 27px;
		display: flex;
		justify-content: space-between;

		.mini_title {
			padding-left: 34px;
		}

		.currency_wrapper {
			padding-right: 30px;
			font-size: 11px;

			.money {
				font-size: 19px;
				font-weight: bold;
				padding-right: 10px;
			}
		}

		@media ${devices.sm} {
			.money_wrapper {
			}
			.mini_title {
				padding-left: 3%;
			}
		}
	}

	.listWrapper {
		border-top: 1px solid #d4d6dc;
		width: 100%;

		th {
			text-align: center;
			font-size: 11px;
			background: white;
			border-top: 1px solid #d4d6dc;
			padding: 6px 0;
			color: #666;
		}

		td {
			border-bottom: 1px solid #eeeef5;
			height: 60px;
		}

		tbody tr:first-child {
			border-top: 1px solid #eeeef5;
		}

		img {
			width: 30px;
			height: 30px;
			margin-right: 10px;
		}

		tbody {
			tr {
				td:first-child {
					width: 100%;
					display: flex;

					.td1_wrapper {
						margin-left: 34px;
						display: flex;
						align-items: center;

						div {
							display: flex;
							flex-direction: column;
							font-size: 11px;
							line-height: 1;

							span:nth-child(1) {
								font-weight: bold;
								color: black;
								font-size: 12px;
							}
						}
					}
				}

				td:not(:last-child) {
					border-right: 1px solid #eeeef5;
				}

				.td2_wrapper {
					display: flex;
					flex-direction: column;
					padding-right: 13px;

					div {
						text-align: right;
						font-size: 12px;
						span {
							font-size: 13px;
							color: black;
							font-weight: bold;
						}
					}
				}

				.td3_wrapper {
					display: flex;
					justify-content: center;

					.rotate {
						animation: rotate 1s;
					}

					@keyframes rotate {
						from {
							transform: rotate(0deg);
						}
						to {
							transform: rotate(360deg);
						}
					}
				}

				.td4_wrapper {
					font-size: 10px;
					text-align: center;
					color: #3736ff;
					font-weight: 600;
				}
			}
		}
	}

	.bottom_message {
		margin-left: 6%;
		margin-top: 1%;
		font-size: 13px;
		padding-bottom: 150px;
	}
`;

const Container = styled.div`
	width: 100%;
	max-width: 1500px;
	margin: 0px auto;
	.col-lg-6 {
		display: flex;
		justify-content: center;
	}
`;

const Main = () => {
	const navigate = useNavigate();
	const [error, setError] = useState(null);
	const isLoggedIn = useSelector((state) => state.userReducer.user);
	const user = useSelector((state) => state.userReducer.user);

	const addComma = (price) => {
		let returnString = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return returnString;
	};

	const [rotate, setRotate] = useState(false);
	const [rotate1, setRotate1] = useState(false);
	const [rotate2, setRotate2] = useState(false);
	const [rotate3, setRotate3] = useState(false);
	const [rotate4, setRotate4] = useState(false);
	const [rotate5, setRotate5] = useState(false);

	const currnetAvs = user.userinfo.avs;
	const currnetAvsl = user.userinfo.avsl;
	const usdkrw = user.userinfo.usdkrw;
	const avsusd = user.userinfo.avsusd;
	const currnetkrw = user.userinfo.krw;
	const avskrw = (currnetAvs * avsusd * usdkrw).toFixed(2);
	const avslkrw = (currnetAvsl * avsusd * usdkrw).toFixed(2);
	const [totalKRW, setTotalKRW] = useState(0);

	useEffect(() => {
		setTimeout(() => {
			setTotalKRW(parseFloat(avskrw) + parseFloat(currnetkrw) + parseFloat(avslkrw));
		}, 1000);
	});

	const logout = () => {
		axios.post("/api/logout.php").then((res) => {
			// console.log(res.data);
			store.dispatch({
				type: "LOG_OUT",
				users: res.data.userinfo,
			});
			navigate("/");
		});
	};

	useEffect(() => {
		axios.post("/api/login_check.php").then((res) => {
			if (res.data.userID == null || res.data.userID == "") {
				logout();
			}
		});
	}, []);

	const handleClick = (coins, params) => {

		const paramst = {
			userID: user.userID,
		};
		axios
			.post("/api/update.php", paramst)
			.then((res) => {
				if (res.status === 200 && res.data.status === "success") {
					store.dispatch({
						type: "RELOAD",
						data: res.data.userinfo.id,
						users: res.data.userinfo,
					});
				}
			})

		if (params == 0) {
			setRotate(!rotate);
			setTimeout(() => {
				setRotate(rotate);
			}, 1000);
			setTimeout(() => {
				alert(coins + "입금이 조회되었습니다");
			}, 1000);
		} else if (params == 1) {
			setRotate1(!rotate1);
			setTimeout(() => {
				setRotate1(rotate1);
			}, 1000);
			setTimeout(() => {
				alert(coins + "입금이 조회되었습니다");
			}, 1000);
		} else if (params == 2) {
			setRotate2(!rotate2);
			setTimeout(() => {
				setRotate2(rotate2);
			}, 1000);
			setTimeout(() => {
				alert(coins + "입금이 조회되었습니다");
			}, 1000);
		} else if (params == 3) {
			setRotate3(!rotate3);
			setTimeout(() => {
				setRotate3(rotate3);
			}, 1000);
			setTimeout(() => {
				alert(coins + "입금이 조회되었습니다");
			}, 1000);
		} else if (params == 4) {
			setRotate4(!rotate4);
			setTimeout(() => {
				setRotate4(rotate4);
			}, 1000);
			setTimeout(() => {
				alert(coins + "입금이 조회되었습니다");
			}, 1000);
		} else if (params == 5) {
			setRotate5(!rotate5);
			setTimeout(() => {
				setRotate5(rotate5);
			}, 1000);
			setTimeout(() => {
				alert(coins + "입금이 조회되었습니다");
			}, 1000);
		}

	};

	return (
		<>
			<Container>
				<div className="row flex-sm-column flex-md-row">
					<div className="col-lg-6 col-sm-12 p-0 p-md-3">
						<MainWrapper>
							<div className="money_wrapper">
								<div className="mini_title">총 보유 자산</div>
								<div className="currency_wrapper">
									<span className="money">{addComma(totalKRW.toFixed(2))}</span>
									<span>KRW</span>
								</div>
							</div>
							<table className="listWrapper">
								<thead>
									<tr>
										<th>코인명</th>
										<th>보유수량(평가금액)</th>
										<th>새로고침</th>
										<th>상태</th>
									</tr>
								</thead>
								<tbody>
									{/* AVS */}
									<tr
										onClick={() => {
											navigate("./AVS", {
												state: {
													coinname: "ArtVibes",
													unit: "AVS",
													fee: 2,
												},
											});
										}}
									>
										<td>
											<div className="td1_wrapper">
												<img src={avs} alt="" />
												<div>
													<span>ArtVibes</span>
													<span>AVS</span>
												</div>
											</div>
										</td>

										<td>
											<div className="td2_wrapper">
												<div>
													<span>{addComma(user.userinfo.avs)}</span> AVS
												</div>
												<div>
													<span>{addComma(avskrw)}</span> KRW
												</div>
											</div>
										</td>
										<td>
											<div className="td3_wrapper">
												<FaRotate
													size={25}
													className={`${rotate ? "rotate" : ""}`}
													onClick={() => {
														handleClick("AVS", 0);
													}}
												/>
											</div>
										</td>
										<td>
											<div className="td4_wrapper">
												<span>입출금 가능</span>
											</div>
										</td>
									</tr>

									{/* ART_LOCKUP */}
									<tr
										onClick={() => {
											navigate("./AVS_LockUp", {
												state: {
													coinname: "AVS_LockUp",
													unit: "AVSL",
													fee: 2,
												},
											});
										}}
									>
										<td>
											<div className="td1_wrapper">
												<img src={avs} alt="" />
												<div>
													<span>AVS LockUp</span>
													<span>AVS_LockUp</span>
												</div>
											</div>
										</td>
										<td>
											<div className="td2_wrapper">
												<div>
													<span>{addComma(user.userinfo.avsl)}</span> AVSL
												</div>
												<div>
													<span>{addComma(avslkrw)}</span> KRW
												</div>
											</div>
										</td>
										<td>
											<div className="td3_wrapper">
												<FaRotate
													size={25}
													className={`${rotate1 ? "rotate" : ""}`}
													onClick={() => {
														handleClick("AVS_LockUp", 1);
													}}
												/>
											</div>
										</td>
										<td>
											<div className="td4_wrapper">
												<span className="text-danger">입출금 불가능</span>
											</div>
										</td>
									</tr>

									<tr
										onClick={() => {
											navigate("./KRW", {
												state: {
													coinname: "KRW",
													unit: "원",
													fee: 1000,
												},
											});
										}}
									>
										<td>
											<div className="td1_wrapper">
												<img src={krw} alt="" />
												<div>
													<span>KRW</span>
													<span>KRW</span>
												</div>
											</div>
										</td>
										<td>
											<div className="td2_wrapper">
												<div>
													<span>{addComma(user.userinfo.krw)}</span> KRW
												</div>
												<div>
													<span>{addComma(user.userinfo.krw)}</span> KRW
												</div>
											</div>
										</td>
										<td>
											<div className="td3_wrapper">
												<FaRotate
													size={25}
													className={`${rotate2 ? "rotate" : ""}`}
													onClick={() => {
														handleClick("KRW", 2);
													}}
												/>
											</div>
										</td>
										<td>
											<div className="td4_wrapper">
												<span>입출금 가능</span>
											</div>
										</td>
									</tr>

									<tr
										onClick={() => {
											navigate("./BTC", {
												state: {
													coinname: "BTC",
													unit: "BTC",
													fee: 0.00002,
												},
											});
										}}
									>
										<td>
											<div className="td1_wrapper">
												<img src={btc} alt="" />
												<div>
													<span>Bitcoin</span>
													<span>BTC</span>
												</div>
											</div>
										</td>
										<td>
											<div className="td2_wrapper">
												<div>
													<span>{user.userinfo.btc}</span> BTC
												</div>
												<div>
													<span>0</span> KRW
												</div>
											</div>
										</td>
										<td>
											<div className="td3_wrapper">
												<FaRotate
													size={25}
													className={`${rotate3 ? "rotate" : ""}`}
													onClick={() => {
														handleClick("BTC", 3);
													}}
												/>
											</div>
										</td>
										<td>
											<div className="td4_wrapper">
												<span>입출금 가능</span>
											</div>
										</td>
									</tr>

									<tr
										onClick={() => {
											navigate("./ETH", {
												state: {
													coinname: "ETH",
													unit: "ETH",
													fee: 0.02,
												},
											});
										}}
									>
										<td>
											<div className="td1_wrapper">
												<img src={eth} alt="" />
												<div>
													<span>Ethereum</span>
													<span>ETH</span>
												</div>
											</div>
										</td>
										<td>
											<div className="td2_wrapper">
												<div>
													<span>{user.userinfo.eth}</span> ETH
												</div>
												<div>
													<span>0</span> KRW
												</div>
											</div>
										</td>
										<td>
											<div className="td3_wrapper">
												<FaRotate
													size={25}
													className={`${rotate4 ? "rotate" : ""}`}
													onClick={() => {
														handleClick("ETH", 4);
													}}
												/>
											</div>
										</td>
										<td>
											<div className="td4_wrapper">
												<span>입출금 가능</span>
											</div>
										</td>
									</tr>

									<tr
										onClick={() => {
											navigate("./XRP", {
												state: {
													coinname: "XRP",
													unit: "XRP",
													fee: 0.02,
												},
											});
										}}
									>
										<td>
											<div className="td1_wrapper">
												<img src={xrp} alt="" />
												<div>
													<span>Ripple</span>
													<span>XRP</span>
												</div>
											</div>
										</td>
										<td>
											<div className="td2_wrapper">
												<div>
													<span>{user.userinfo.xrp}</span> XRP
												</div>
												<div>
													<span>0</span> KRW
												</div>
											</div>
										</td>
										<td>
											<div className="td3_wrapper">
												<FaRotate
													size={25}
													className={`${rotate5 ? "rotate" : ""}`}
													onClick={() => {
														handleClick("XRP", 5);
													}}
												/>
											</div>
										</td>
										<td>
											<div className="td4_wrapper">
												<span>입출금 가능</span>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
							<div className="bottom_message">※ &apos;입금 확인&apos;을 하기위해 새로고침 버튼을 눌러주세요.</div>
						</MainWrapper>
					</div>
					<div className="col-lg-6 col-sm-12 p-0 p-md-3">
						<Routes>
							<Route path="/" element={<CoinDeposit />}>
								<Route path="/AVS" element={<CoinDeposit />} />
								<Route path="/ETH" element={<CoinDeposit />} />
								<Route path="/BTC" element={<CoinDeposit />} />
								<Route path="/XRP" element={<CoinDeposit />} />
								<Route path="/AVS_LockUp" element={<CoinDeposit />} />
							</Route>
							<Route path="/KRW" element={<KRWDeposit />} />
						</Routes>
					</div>
				</div>
			</Container>
		</>
	);
};

export default Main;
