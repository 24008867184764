import React from 'react';
import { useState } from "react";
import { styled } from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Form, Alert, Select, Input, Button } from 'antd';
import Swal from "sweetalert2";


const RegisterForm = styled.div`
    .global_bg {
        background-color:#961e23;
    }

    .global_bg:hover {
        transition:all 0.3s ease;
        background-color:#961e23 !important;
        filter:brightness(130%);
    }
`



const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

const tailLayout = {
    wrapperCol: { offset: 0, span: 24 },
};

const { Option } = Select;
const Register = () => {
    const navigate = useNavigate();
    const initData = {
        status: '',
        message: '',
        userinfo: {
            idx: '',
            id: '',
            username: '',
            money: '',
            phone: '',
            email: '',
            w_password: ''
        }
    }


    const [formData, setFormData] = useState(initData);
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);

    const onChangeInput = (e) => {
        setFormData({
            ...formData,
            [e.target.dataset.name2]: e.target.value
        });
    }

    const submitForm = (e) => {
        const params = {
            userID: formData.userID,
            password: formData.password,
            passwordchk: formData.passwordchk,
            username: formData.username,
            email: formData.email,
            phone: formData.phone,
            walletpassword: formData.walletpassword
        }

       

        axios.post('/api/register.php', params)
            .then((res) => {
                
                if (res.status === 200 && res.data.status === 'success') {
                    setUsers(res.data.userinfo);

                    Swal.fire({
                        icon: "success",
                        title: "가입 완료",
                        text: '회원 가입 완료',
                        timer: 1500,
                    }).then((res) => {
                        navigate('/');
                    });
                } else if (res.data.status === 'error') {
                    setError(true);
                    if (res.data.message === "아이디 중복") {
                        setErrorMsg("이미 가입된 아이디 입니다")
                    } else if (res.data.message === "비밀번호 확인") {
                        setErrorMsg("비밀번호가 일치하지 않습니다.")
                    }
                } else {
                    setError(true);
                }
            })
            .catch(error => {
                console.log(error.response)
            });
    }

    return (
        <>
            <RegisterForm>
                <Form
                    onFinish={submitForm}
                    {...layout}
                    autoComplete="off"
                >
                    <Form.Item
                        label="아이디"
                        name="userID"
                        rules={[
                            {
                                required: true,
                                message: '아이디를 입력해주세요',
                            },
                        ]}
                    >
                        <Input
                            onChange={onChangeInput}
                            value={formData.userID}
                            data-name2="userID"

                        />
                    </Form.Item>

                    <Form.Item
                        label="비밀번호"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: '비밀번호를 입력해주세요',
                            },
                        ]}
                    >
                        <Input.Password
                            onChange={onChangeInput}
                            value={formData.password}
                            data-name2="password"
                        />
                    </Form.Item>

                    <Form.Item
                        label="비밀번호확인"
                        name="passwordchk"
                        rules={[
                            {
                                required: true,
                                message: '비밀번호확인을 입력해주세요',
                            },
                        ]}
                    >
                        <Input.Password
                            onChange={onChangeInput}
                            value={formData.passwordchk}
                            data-name2="passwordchk"
                        />
                    </Form.Item>

                    <Form.Item
                        label="이름"
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: '이름을 입력해주세요',
                            },
                        ]}
                    >
                        <Input onChange={onChangeInput}
                            value={formData.username}
                            data-name2="username"
                        />
                    </Form.Item>
                    <Form.Item
                        label="전화번호"
                        name="phone"
                        rules={[
                            {
                                required: true,
                                message: '전화번호를 입력해주세요',
                            },
                        ]}
                    >
                        <Input
                            onChange={onChangeInput}
                            value={formData.phone}
                            data-name2="phone"
                        />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label="이메일"
                        rules={[
                            {
                                required: true,
                                message: '이메일을 입력해주세요',
                            },
                        ]}
                    >
                        <Input
                            onChange={onChangeInput}
                            value={formData.email}
                            data-name2="email" />
                    </Form.Item>
                    <Form.Item
                        label="지갑비밀번호"
                        name="walletpassword"

                        rules={[
                            {
                                required: true,
                                message: '지갑비밀번호를 입력해주세요',
                            },
                        ]}
                    >
                        <Input.Password
                            data-name2="walletpassword"
                            onChange={onChangeInput}
                            value={formData.walletpassword} />
                    </Form.Item>

                    {error &&
                        <Alert message={errorMsg} type='error'>
                        </Alert>
                    }
                    <Form.Item
                        {...tailLayout}
                    >
                        <Button type="primary" htmlType="submit" size="large" className='global_bg w-100'>회원가입</Button>
                    </Form.Item>
                </Form>
            </RegisterForm>
        </>
    )
}

export default Register