import { styled } from "styled-components";
import { Mobile, PC } from "./ResponsiveController";
import { BiChevronRight } from "react-icons/bi";
import { NavLink, Route, Routes } from "react-router-dom";
import Security from "./Mypage/Security";
import LoginHistory from "./Mypage/LoginHistory";
import Password from "./Mypage/Password";
import Delete from "./Mypage/Delete";
import { devices } from "./Constants/Device";

const Container = styled.div`
	width: 100%;
	max-width: 1500px;
	margin: 0px auto;
	min-height: calc(100vh - 200px);
	margin-bottom: 80px;

	.col-lg-3 {
		display: flex;
		justify-content: center;
		margin-top: 75px;
	}

	@media ${devices.lg} {
		.col-lg-3 {
			display: none;
		}
	}

	.side_bar {
		width: 270px;
		.side_title {
			text-align: CENTER;
			color: white;
			padding: 22px;
			font-size: 16px;
			font-weight: bold;
			background-color: #141d38;
		}
		.side_body {
			text-align: center;
			color: #000000 !important;

			div {
				font-weight: 700;
				a {
					text-decoration: none;
					color: #000000;
					background-color: #ffffff;
					text-align: center;
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					height: 50px;
					font-size: 13px;
					position: relative;
				}

				a.active {
					background-color: #ececec;
				}

				a:not(.active):hover {
					background-color: #e7eaed;
					color: #961e23;
					font-weight: 700;
				}

				svg {
					position: absolute;
					left: 90%;
				}
			}
		}
	}

	.l_body {
		.wr-1 {
			@media ${devices.lg} {
				& {
					margin-top: 0px;
				}
			}

			margin-top: 75px;
			background-color: #ffffff;
			padding: 60px 60px;
		}
		.line {
			background-color: #961e23;
			float: left;
			width: 8px;
			height: 28px;
			border-radius: 2px;
			margin-right: 8px;
		}

		.title {
			font-size: 18px;
			font-weight: bold;
			padding-bottom: 10px;
		}

		.subtitle {
			margin-left: 2%;
			margin-top: 1%;
			font-size: 13px;
		}
	}
`;

const Mypage = () => {
	return (
		<>
			<Container>
				<div className="row flex-sm-column flex-md-row">
					<PC>
						<div className="col-lg-3">
							<div className="side_bar">
								<div className="side_title">마이페이지</div>
								<div className="side_body">
									<div>
										<NavLink to="./">
											보안인증
											<BiChevronRight size={20} />
										</NavLink>
									</div>
									<div>
										<NavLink to="./loginhistory">
											로그인 내역
											<BiChevronRight size={20} />
										</NavLink>
									</div>
									<div>
										<NavLink to="./password">
											비밀번호 변경
											<BiChevronRight size={20} />
										</NavLink>
									</div>
									<div>
										<NavLink to="./delete">
											회원 탈퇴
											<BiChevronRight size={20} />
										</NavLink>
									</div>
								</div>
							</div>
						</div>
					</PC>
					<div className="col-lg-9 l_body">
						<Routes>
							<Route path="/" element={<Security />} />
							<Route path="/loginhistory" element={<LoginHistory />} />
							<Route path="/password" element={<Password />} />
							<Route path="/delete" element={<Delete />} />
						</Routes>
					</div>
				</div>
			</Container>
		</>
	);
};

export default Mypage;
