import { useState, useCallback, useEffect } from "react";
import { Route, Routes, useNavigate } from 'react-router-dom';
import { styled } from "styled-components";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import { Form, Alert, Select, Input, Button } from 'antd';
import store from "./store/store";
import bg01 from '../img/bg-core.png'
import logo from '../img/logo.png'
import Register from "./Register";
import LoginFile from "./LoginFile";
import { useSelector } from "react-redux";

const LoginForm = styled.div`
    width:100%;

    .h-screen {
        display:flex;
        justify-content:center;
        height:100vh;
        background-image:url(${bg01});
        background-size:cover;

        .container {
            display:flex;
            align-items:center;
            justify-content:center;
        }

        img {
            width:450px;
        }
    }

    .navWrapper {
        border-bottom: 2px dashed #eceff5;
        display:flex;
        margin-bottom:25px;

        & > a {
            text-decoration:none;
            color:black;
            padding:10px;
            display:block;
            position:relative;
            top:3px;
        }

        & > a.active {
            color : #961e23;
            border-bottom: 3px solid #961e23;
        }
    }

    .card-footer {
        font-size:12px;
        text-align:center;
        font-family:'Apple SD Gothic Neo' !important
    }
`;

const Login = () => {
    const isLoggedIn = useSelector((state) => state.userReducer.user.isLoggedIn)
    const navigate = useNavigate();
    
    useEffect(() => {
		if(isLoggedIn) {
            navigate("/main");
        }
	},[]);

    return (
        <>
            <LoginForm>
                <section className="h-screen">
                    <div className="container">
                        <div className="card">
                            <img src={logo} className="card-img-top" alt="..." />
                            <div className="card-body">
                                <div className="navWrapper">
                                    <NavLink to="/">로그인</NavLink>
                                    <NavLink to="/register">회원가입</NavLink>
                                </div>


                                <Routes>
                                    <Route path="/" element={<LoginFile />} />
                                    <Route path="/register" element={<Register />} />
                                </Routes>

                            </div>
                            <div className="card-footer">
                            COPYRIGHT © 2023 ARTVIBES ALL RIGHTS RESERVED.
                            </div>

                        </div>

                    </div>
                </section>

            </LoginForm>
        </>
    )
}

export default Login