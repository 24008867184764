import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "styled-components";
import store from "../store/store";

const TableWrapper = styled.table`
	width: 100%;
	text-align: center;
	max-height: 250px;
	overflow-y: auto;
	height: 200px;

	thead {
		th {
			height: 45px;
			font-size: 14px;
			text-align: center;
		}
	}

	tbody {
		td {
			color: #000;
			text-align: center;
			font-size: 13px;
			font-weight: bold;
			height: 45px;
		}
	}
`;

const LoginHistory = () => {
	const user = useSelector((state) => state.userReducer.user);

	const [loading, setLoading] = useState(true);
	const [iplist, setIplist] = useState(null);
	useEffect(() => {
		const params = {
			userID: user.userID,
		};

		axios
			.post("/api/login_history.php", params)
			.then((res) => {
				if (res.status === 200 && res.data.status === "success") {
					setIplist(res.data.login_log);
					setLoading(false);
				}
			})
			.catch((error) => {
				console.log(error.response);
			});
	}, []);

	return (
		<>
			<div className="wr-1">
				<div className="title">
					<div className="line"></div>
					로그인 내역
				</div>
				<div className="subtitle">회원님의 로그인 내역을 확인할 수 있습니다.</div>

				<div style={{ overflowY: "auto", height: "400px" }}>
					<TableWrapper>
						<thead>
							<tr>
								<th>아이디</th>
								<th>로그인 상태</th>
								<th>IP</th>
								<th>날짜</th>
							</tr>
						</thead>
						<tbody>
							{!iplist
								? "불러오는중"
								: iplist.map((dgg, index) => {
										return (
											<tr key={index}>
												<td key={dgg.id}>{dgg.id}</td>
												<td key={dgg.status}>{dgg.status == 0 ? "로그인 실패" : "로그인 성공"}</td>
												<td key={dgg.ip}>{dgg.ip}</td>
												<td key={dgg.time}>{dgg.time}</td>
											</tr>
										);
								})}
						</tbody>
					</TableWrapper>
				</div>
			</div>
		</>
	);
};

export default LoginHistory;
