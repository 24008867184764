import { styled } from "styled-components";
import { devices } from "./Constants/Device";
import { useEffect, useState } from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import { QRCodeSVG } from "qrcode.react";
import { Form, Alert, Select, Input, Button } from "antd";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import store from "./store/store";
import axios from "axios";
const DepositWrapper = styled.div`
	background-color: #fff;
	width: 100%;
	margin-top: 75px;
	margin-bottom: 80px;
	width: 600px;
	min-height: 750px;
	padding: 30px 60px;

	@media ${devices.lg} {
		& {
			width: 100%;
			height: auto;
			min-height: auto;
			margin-bottom: 10px;
			margin-top: 0px;
		}
	}

	.line {
		background-color: #961e23;
		float: left;
		width: 8px;
		height: 28px;
		border-radius: 2px;
		margin-right: 8px;
	}

	.title {
		font-size: 18px;
		font-weight: bold;
		padding-bottom: 10px;
	}

	.balance_1 {
		display: flex;
		justify-content: space-between;
		padding: 20px 0;
		background-color: #fbfbfc;
		font-size: 13px;
		font-weight: bold;
		line-height: 1;
		align-items: center;
		div:nth-child(1) {
			font-size: 13px;
			padding-left: 20px;
		}

		div:nth-child(2) {
			display: flex;
			align-items: center;
			font-size: 17px;
			span {
				margin-left: 8px;
				font-size: 13px;
				font-weight: normal;
				margin-right: 20px;
			}
		}
	}

	.bodyWrapper {
		.bodyTitle {
			ul {
				list-style: none;
				display: flex;
				border-top: 1px solid #dee2e6;
				border-bottom: 1px solid #dee2e6;
				padding: 0;
				margin-bottom: 0;

				li {
					width: 33%;
					text-align: center;
					font-size: 14px;
					font-weight: bold;
					padding: 11px 0;
					cursor: pointer;
				}

				li.active {
					color: #961e23;
					border-bottom: 3px solid #961e23;
				}
			}
		}
		.bodyBody {
			.deposit_01 {
				text-align: center;
				padding: 20px 0;
				border-bottom: 1px solid #f1f1f4;
				font-size: 12px;
				font-weight: bold;
			}

			.deposit_02 {
				padding: 27px 20px;
				.d02_title {
					font-size: 13px;
					color: #666;
					padding-bottom: 7px;
				}

				input {
					padding: 10px;
					width: 88%;
					background: whitesmoke;
					border: 1px solid #dfe0e5;
					font-size: 12px;
					border-radius: 2px 0 0 2px;
				}
				button {
					padding: 10px;
					float: right;
					width: 12%;
					background: #f9fafc;
					border: 1px solid #dfe0e5;
					color: black;
					font-weight: bold;
					text-align: center;
					font-size: 12px;
					border-radius: 0 2px 2px 0;
					word-break:keep-all;
				}

				.d02_title2 {
					font-size: 13px;
					color: #666;
					margin-top: 27px;
					margin-bottom: 15px;
				}

				.d02_body2 {
					display: flex;
					justify-content: center;
				}
			}

			.deposit_03 {
				padding: 10px 20px;
				font-size: 13px;
				border-top: 1px solid #f1f1f4;

				span {
					color: red;
					font-weight: bold;
				}
			}

			.withdraw_01 {
				padding: 20px 0;
				border-bottom: 1px solid #f1f1f4;
				display: flex;
				justify-content: space-between;
				padding-left: 20px;
				padding-right: 20px;
				font-size: 13px;

				div:nth-child(2) {
					font-size: 12px;

					span {
						font-size: 13px;
						color: black;
						font-weight: bold;
						margin-right: 8px;
					}
				}
			}

			.withdraw_02 {
				ul {
					list-style: none;
					display: flex;
					border-top: 1px solid #dee2e6;
					border-bottom: 1px solid #dee2e6;
					padding: 0;
					margin-bottom: 20px;

					li {
						width: 50%;
						text-align: center;
						font-size: 14px;
						font-weight: bold;
						padding: 11px 0;
						cursor: pointer;
					}

					li.active {
						color: #961e23;
						border-bottom: 2px solid #961e23;
					}
				}

				.w02_body {
					input {
						border-radius: 0;
						padding: 10px;
						width: 100%;
						border: 1px solid #dfe0e5;
						font-size: 12px;
						transition: all 1s ease;
						outline: none;
					}

					input:focus {
						border-radius: 0;
						outline: 1px solid #961e23;
						transition: all 1s ease;
					}

					.amounts {
						text-align: right;
					}

					.fg_title {
						font-size: 13px;
						width: 18%;
						display: flex;
						align-items: center;
					}

					.form_group {
						display: flex;
					}

					.btn_group {
						display: flex;
						width: 100%;
						a {
							padding: 7px;
							border: 1px solid;
							border: 1px solid #dfe0e5;
							width: 100%;
							color: black;
							font-size: 12px;
							text-align: center;
							border-radius: 2px;
							background-color: transparent;
							transition: all 0.5s ease;
							text-decoration: none;
							cursor: pointer;
						}
						a:not(:first-child) {
							margin-left: 6px;
						}
						a:hover {
							color: #fff;
							background-color: #961e23;
						}
						a.clicked {
							color: #fff;
							background-color: #961e23;
						}
					}

					.form_group + .form_group {
						margin-top: 10px;
					}
					.ant-form-item-required span {
						display: none;
					}

					.ant-form-item-required:before {
						display: none !important;
					}

					.form_group_2 {
						margin: 10px 0px;
						padding: 20px 0;
						border-bottom: 1px solid #f1f1f4;
						border-top: 1px solid #f1f1f4;
						display: flex;
						justify-content: space-between;

						div:nth-child(2) {
							display: flex;
							align-items: center;
							font-size: 12px;

							span {
								font-size: 13px;
								color: black;
								font-weight: bold;
								margin-right: 8px;
							}
						}
					}

					.form_group_2._2 {
						border-bottom: none;
					}
					.withdraw_03 {
						margin-top: 10px;
						padding: 10px 20px;
						font-size: 13px;
						border-top: 1px solid #f1f1f4;

						span {
							color: red;
							font-weight: bold;
						}
					}

					.withdraw_04 {
						padding: 20px 0;
						border-top: 1px solid #f1f1f4;

						button {
							width: 100%;
							display: block;
							padding: 10px 0;
							text-align: center;
							background: #961e23;
							color: white;
							border-radius: 2px;
							border: none;
						}

						button:hover {
							transition: all 0.3s ease;
							background-color: #961e23 !important;
							filter: brightness(130%);
						}
					}
				}
			}
		}
	}

	.text-center {
		font-size: 13px;
		padding-top: 20px;
		padding-bottom: 30px;
	}
`;
const RecordWrapper = styled.div`
	table {
		text-align: center;
		width:100%;
		th {
			padding: 10px 0px;
		}
		td {
			width: 25%;
		}
	}
`;

const coin_init = {
	state: {
		coinname: "ArtVibes",
		unit: "AVS",
		fee: 2,
		min: 2,
	},
};


const KRWDeposit = () => {
	const coin = useLocation();
	const coininfo = () => {
		if (coin.state == null) {
			return coin_init.state;
		} else {
			return coin.state;
		}
	};

	const cname = coininfo().coinname;
	const unit = coininfo().unit;
	const fee = coininfo().fee;

	const [tab, setTab] = useState(0);
	const [wtab, setWTab] = useState(0);
	const [clicked, setClicked] = useState(0);
	const [pvalue, setPvalue] = useState(10000);
	const [totalValue, setTotalValue] = useState(0);
	const user = useSelector((state) => state.userReducer.user);
	const [error, setError] = useState(false);

	

	


	useEffect(() => {
		setPvalue(0);
		setTotalValue(0);
	}, [coin]);

	useEffect(() => {
		const params = {
			userID: user.userID,
			coin: unit,
		};

		axios
			.post("/api/deposit_history.php", params)
			.then((res) => {
				if (res.status === 200 && res.data.status === "success") {
					store.dispatch({
						type: "LOAD",
						data: res.data.depositData,
					});
			
				} else {
					setError(true);
				}
			})
			.catch((error) => {
				console.log(error.response);
			});
	},[unit])

	const depositData = useSelector((state) => state.recordReducer.deposit);
	

	const addComma = (price) => {
		let newprice = (price != null) ? price.toString().replace(/,/g, "") : 0;
		// let returnString = newprice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");\
		const returnString = Number(newprice).toLocaleString();
		return returnString;
	};

	const handleCopyClipBoard = async (text) => {
		try {
			await navigator.clipboard.writeText(text);

			alert("복사 성공!");
		} catch (error) {
			alert("복사 실패!");
		}
	};

	const handlePrice = (params) => {
		setClicked(params);
		if (params == 1) {
			setPvalue(user.userinfo.money / 10);
			setTotalValue(user.userinfo.money / 10);
		} else if (params == 2) {
			setPvalue(user.userinfo.money / 4);
			setTotalValue(user.userinfo.money / 4);
		} else if (params == 3) {
			setPvalue(user.userinfo.money / 2);
			setTotalValue(user.userinfo.money / 2);
		} else if (params == 4) {
			setPvalue(user.userinfo.money);
			setTotalValue(user.userinfo.money);
		}
	};

	const handleChange = (event) => {
		//코인 보유량으로 변경하기(money 부분)
		let evalue = parseInt(event.target.value.toString().replace(/,/g, ""));
		let usermoney = parseInt(user.userinfo.money.toString().replace(/,/g, ""));

		if (isNaN(evalue)) {
			setPvalue(addComma(0));
			setTotalValue(addComma(0));
			return false;
		}

		if (evalue > usermoney) {
			setPvalue(addComma(user.userinfo.money));
			if (wtab == 0) {
				setTotalValue(addComma(user.userinfo.money));
			} else {
				setTotalValue(addComma(usermoney - fee));
			}
		} else {
			setPvalue(addComma(evalue));
			if (wtab == 0) {
				setTotalValue(addComma(evalue));
			} else {
				setTotalValue(addComma(evalue - fee));
			}
		}
	};

	const currentMoney = () => {
		switch (unit) {
			case "AVS":
				return user.userinfo.avs;
			case "원":
				return user.userinfo.krw;
			case "ETH":
				return user.userinfo.eth;
			case "AVSL":
				return user.userinfo.avsl;
			default:
				return user.userinfo.avs;
		}
	};

	const walletAddress = () => {
		switch (unit) {
			case "AVS":
				return user.userinfo.avs_wallet;
			case "원":
				return 0;
			case "ETH":
				return user.userinfo.eth_wallet;
			case "AVSL":
				return 0;
			default:
				return user.userinfo.avs;
		}
	};

	return (
		<>
			<DepositWrapper>
				<div className="title">
					<div className="line"></div>
					<span>{cname.toUpperCase()}</span> 입출금 {unit == "AVSL" && <span className="text-danger"> (입출금 불가능)</span>}
				</div>
				<div className="balance_1">
					<div>보유수량</div>
					<div>
						{addComma(currentMoney())}
						<span>{unit}</span>
					</div>
				</div>
				<div className="bodyWrapper">
					<div className="bodyTitle">
						<ul>
							<li
								className={`${tab == 0 ? "active" : ""}`}
								onClick={() => {
									setTab(0);
								}}
							>
								입금하기
							</li>
							<li
								className={`${tab == 1 ? "active" : ""}`}
								onClick={() => {
									setTab(1);
								}}
							>
								출금하기
							</li>
							<li
								className={`${tab == 2 ? "active" : ""}`}
								onClick={() => {
									setTab(2);
								}}
							>
								입출금내역
							</li>
						</ul>
					</div>
					{tab == 0 ? (
						<div className="bodyBody">
							<div className="deposit_01">
								<RiArrowDownSLine size="20" color="#961e23" /> 회원님에게 할당된 전용계좌로 원화를 입금할 수 있습니다.
							</div>
							<div className="deposit_02">
								<div className="d02_title">입금 전용 계좌</div>
								<div className="d02_body">
									<input type="text" readOnly value={walletAddress() == 0 ? "없음" : walletAddress()} />
									<button onClick={() => handleCopyClipBoard(walletAddress() == 0 ? "없음" : walletAddress())}>복사</button>
								</div>

							</div>
							<div className="deposit_03">
								<span>※ 입금 전 꼭 알아두세요!</span>
								<br></br>- 위 계좌는 입금전용계좌 입니다.<br></br>- 입금 이후 5분 이내에 잔고에 반영됩니다.
							</div>
						</div>
					) : null}
					{tab == 1 ? (
						<div className="bodyBody">
							<div className="withdraw_01">
								<div>출금 가능</div>
								<div>
									<span>{addComma(currentMoney())}</span>
									{unit}
								</div>
							</div>
							<div className="withdraw_02">
								<div className="w02_body">
									<form action="">
										<div className="form_group">
											<div className="fg_title">계좌번호</div>
											<input type="text" placeholder="입금 받을 계좌번호를 입력해주세요" />
										</div>
										<div className="form_group">
											<div className="fg_title">출금 금액</div>
											<input
												type="text"
												className="amounts"
												placeholder={`최소 출금 수량 : 10000 ${unit}`}
												value={pvalue == 0 ? 10000 : addComma(pvalue)}
												onChange={handleChange}
											/>
										</div>
										<div className="form_group">
											<div className="fg_title"></div>
											<div className="btn_group">
												<a
													className={`${clicked == 1 ? "clicked" : ""}`}
													onClick={() => {
														handlePrice(1);
													}}
												>
													10%
												</a>
												<a
													className={`${clicked == 2 ? "clicked" : ""}`}
													onClick={() => {
														handlePrice(2);
													}}
												>
													25%
												</a>
												<a
													className={`${clicked == 3 ? "clicked" : ""}`}
													onClick={() => {
														handlePrice(3);
													}}
												>
													50%
												</a>
												<a
													className={`${clicked == 4 ? "clicked" : ""}`}
													onClick={() => {
														handlePrice(4);
													}}
												>
													최대
												</a>
											</div>
										</div>

											<div className="form_group_2 _2">
												<div className="fg_title">출금수수료</div>
												<div>
													<span>{fee}</span> {unit}(수수료)
												</div>
											</div>

										<div className="form_group_2">
											<div>총 출금</div>
											<div>
												<span>{addComma(totalValue) == "" ? 0 : addComma(totalValue)}</span> {unit}
											</div>
										</div>
										<div className="form_group">
											<div className="fg_title">메모</div>
											<input type="text" />
										</div>
										<div className="withdraw_03">
											<span>※ 원화 출금 유의사항</span>
											<br></br>- 출금 신청 이후 10분 이내에 입금이 완료됩니다.<br></br>- 관리자 확인 후 수동으로 출금이 이루어지기 때문에 시간이
											다소 걸릴 수 있습니다.
										</div>
										<div className="withdraw_04">
											<button onClick={() => alert("보유금액을 확인해주세요")}>출금신청</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					) : null}
					{tab == 2 ? (
						<div className="bodyBody">
							{depositData.length > 0 ? (
								<RecordWrapper>
									<table>
										<thead>
											<tr>
												<th>가격</th>
												<th>구매갯수</th>
												<th>총가격</th>
												<th>시간</th>
											</tr>
										</thead>
										<tbody>
											{depositData.map((dgg, index) => {
												return (
													<tr key={index}>
														<td>{dgg.price}</td>
														<td>{dgg.quantity}</td>
														<td>{addComma(dgg.total)}</td>
														<td>{dgg.time}</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</RecordWrapper>
							) : (
								<div className="w-100 text-center">조회된 입출금 내역이 없습니다.</div>
							)}
						</div>
					) : (
						""
					)}
				</div>
			</DepositWrapper>
		</>
	);
};

export default KRWDeposit;
