import { useSelector } from "react-redux"
import { styled } from "styled-components"


const FooterDiv = styled.div`
width:100%;
background-color:#fff;
text-align:center;
font-size:14px;
padding: 40px 0px;
color:#555;

`

const Footer = () => {
    // const isLoggedIn = true
    const isLoggedIn = useSelector((state) => state.userReducer.user.isLoggedIn)
    return (
        <>
            {isLoggedIn ?
                <FooterDiv>
                    <span>COPYRIGHT © 2023 ARTVIBE ALL RIGHTS RESERVED.</span>
                </FooterDiv>
                : ""}
        </>
    )
}

export default Footer