import { styled } from "styled-components";
import { Mobile, PC } from "./ResponsiveController";
import { BiChevronRight } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import { devices } from "./Constants/Device";

const Container = styled.div`
	width: 100%;
	max-width: 1500px;
	margin: 0px auto;
	min-height: calc(100vh - 200px);

	.col-lg-3 {
		display: flex;
		justify-content: center;
		margin-top: 75px;
	}
	.side_bar {
		width: 270px;
		.side_title {
			text-align: CENTER;
			color: white;
			padding: 22px;
			font-size: 16px;
			font-weight: bold;
			background-color: #141d38;
		}
		.side_body {
			text-align: center;
			color: #000000 !important;
			font-weight: 700;
			background-color: #ececec;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			height: 50px;
			font-size: 13px;
			position: relative;

			svg {
				position: absolute;
				left: 90%;
			}
		}
	}

	.l_body {
		.wr-1 {
			margin-top: 75px;
			background-color: #ffffff;
			padding: 60px 60px;

			@media ${devices.lg} {
				& {
					margin-top: 0px;
				}
			}
		}

		.line {
			background-color: #961e23;
			float: left;
			width: 8px;
			height: 28px;
			border-radius: 2px;
			margin-right: 8px;
		}

		.title {
			font-size: 18px;
			font-weight: bold;
			padding-bottom: 10px;
		}

		.subtitle {
			margin-left: 2%;
			margin-top: 1%;
			font-size: 13px;
		}
	}
`;
const TableWrapper = styled.table`
	width: 100%;
	text-align: center;
	max-height: 250px;
	overflow-y: auto;
	height: 200px;

	thead {
		th {
			height: 45px;
			font-size: 14px;
			text-align: center;
		}
	}

	tbody {
		td {
			color: #000;
			text-align: center;
			font-size: 13px;
			font-weight: bold;
			height: 45px;

			span.up {
				color: #01a781;
			}

			span.down {
				color: #f44b44;
			}
		}
		@media ${devices.lg} {
			td:nth-child(5), td:nth-child(6){
				width:100px;
			}
		}
	}
`;
const Lockup = () => {
	const user = useSelector((state) => state.userReducer.user);

	const [loading, setLoading] = useState(true);
	const [lockList, setLocklist] = useState(null);

	useEffect(() => {
		const params = {
			userID: user.userID,
		};

		axios
			.post("/api/lockup_list.php", params)
			.then((res) => {
				if (res.status === 200 && res.data.status === "success") {
					setLocklist(res.data.lockData);
					setLoading(false);
				}
			})
			.catch((error) => {
				console.log(error.response);
			});
	}, []);

	return (
		<>
			<Container>
				<div className="row flex-sm-column flex-md-row">
					<PC>
						<div className="col-lg-3">
							<div className="side_bar">
								<div className="side_title">락업 목록</div>
								<div className="side_body">
									<div>
										락업정보
										<BiChevronRight size={20} />
									</div>
								</div>
							</div>
						</div>
					</PC>
					<div className="col-lg-9 l_body">
						<div className="wr-1">
							<div className="title">
								<div className="line"></div>
								락업 정보
							</div>
							<div className="subtitle">회원님의 락업 정보를 확인할 수 있습니다.</div>

							<div style={{ overflowY: "auto", height: "400px" }}>
								<TableWrapper className="table-responsive">
									<thead>
										<tr>
											<th>종류</th>
											<th>구매가격</th>
											<th>현재가격</th>
											<th>총가격</th>
											<th>구매날짜</th>
											<th>락업종료</th>
										</tr>
									</thead>
									<tbody>
										{!lockList
											? "불러오는중"
											: lockList.map((dgg, index) => {
													return (
														<tr key={index}>
															<td key={dgg.coin}>{dgg.coin}</td>
															<td key={dgg.price}>{dgg.price}</td>
															<td key={dgg.today_price}>
																{dgg.today_price} (
																{dgg.diff > 0 ? (
																	<span className="up">+{dgg.diff}%</span>
																) : dgg.diff == 0 ? (
																	<span>{dgg.diff}%</span>
																) : (
																	<span className="down">{dgg.diff}%</span>
																)}
																)
															</td>
															<td key={dgg.total}>{dgg.total}</td>
															<td key={dgg.time}>{dgg.time}</td>
															<td key={dgg.lockup_end}>{dgg.lockup_end}</td>
														</tr>
													);
											})}
									</tbody>
								</TableWrapper>
							</div>
						</div>
					</div>
				</div>
			</Container>
		</>
	);
};

export default Lockup;
